import * as React from "react"

interface Props {
  smallTitle: string
  title: string
  description: string
}

const SimpleHeader = ({ smallTitle, title, description }: Props) => (
  <header className="bg-white">
    <div className="max-w-7xl mx-auto py-9 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div className="text-center">
        <h2 className="text-base font-semibold text-teal-600 tracking-wide uppercase">
          {smallTitle}
        </h2>
        <p className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-4xl sm:tracking-tight">
          {title}
        </p>
        <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
          {description}
        </p>
      </div>
    </div>
  </header>
)

export default SimpleHeader

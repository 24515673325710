import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Quote from "../components/quote"
import ProjectCta from "../components/projectCta"
import { ArrowCircleRightIcon } from "@heroicons/react/outline"
import SimpleHeader from "../components/simpleHeader"
import { graphql, Link } from "gatsby"
import ReviewOverview from "../components/reviewOverview"

const ProjectsPage = ({
  data: {
    contentfulProjects: {
      contactCta,
      projects,
      title,
      introduction,
      testimonial: {
        slug: testimonialSlug,
        address: testimonialAddress,
        customerName,
        featuredImage,
        testimonial: { testimonial: customerTestimonial },
      },
    },
  },
}) => (
  <Layout>
    <Seo title="Projects" />

    <SimpleHeader
      smallTitle="Our Projects"
      title={title}
      description={introduction}
    />

    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="relative max-w-7xl mx-auto">
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {projects.map(
            ({
              id,
              summary,
              featuredImage,
              slug,
              projectType,
              title,
              jobDate,
              address,
            }) => (
              <div
                key={id}
                className="flex flex-col rounded-lg shadow-lg overflow-hidden"
              >
                <div className="flex-shrink-0">
                  <img
                    className="h-48 w-full object-cover"
                    src={featuredImage.file.url}
                    alt={featuredImage.description}
                  />
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <Link to={`/projects/${slug}`} className="block mt-2">
                      <p className="text-sm font-medium text-teal-600">
                        {projectType}
                      </p>
                      <p className="text-xl font-semibold text-gray-900">
                        {title}
                      </p>
                      <p className="mt-3 text-base text-gray-500">{summary}</p>
                    </Link>
                  </div>
                  <div className="mt-3 flex items-center">
                    <div>
                      <p className="text-sm font-medium text-gray-900">
                        {address}
                      </p>
                      {false && (
                        <div className="flex space-x-1 text-sm text-gray-500">
                          {jobDate}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>

    <div className="mb-20">
      <Quote
        projectLink={`/projects/${testimonialSlug}`}
        isClipped={true}
        testimonial={{
          text: customerTestimonial,
          address: testimonialAddress,
          customerName,
          image: featuredImage.file.url,
          imageAlt: featuredImage.description,
        }}
      />
    </div>

    <ReviewOverview />

    <ProjectCta text={contactCta} />
  </Layout>
)

export const query = graphql`
  {
    contentfulProjects {
      contactCta
      title
      introduction
      projects {
        slug
        id
        title
        summary
        introduction
        address
        projectType
        jobDate(fromNow: true)
        featuredImage {
          file {
            url
          }
          description
        }
      }
      testimonial {
        slug
        address
        customerName
        featuredImage {
          file {
            url
          }
          description
        }
        testimonial {
          testimonial
        }
      }
    }
  }
`

export default ProjectsPage
